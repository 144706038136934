<template>
  <BaseModal name="account-statements-filters" size="md" :title="$t('client.text_1649')">
    <b-row>
      <b-col cols="12">
        <b-form novalidate>
          <b-row>        
            <b-col cols="12" data-anima="top" class="mt-2">
              <b-form-group
                :label="$t('seller.sales.text_2615_1')"
                label-for="method"               
              >               
                <multiselect
                  v-model="dados"
                  :placeholder="$t('seller.sales.text_2617_1')"
                  label="name"
                  track-by="id"
                  :options="options"
                  :multiple="true"
                  :taggable="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :selectLabel="$t('seller.sales.text_2618')"
                  :deselectLabel="$t('seller.sales.text_2619')"
                  selectedLabel="✔️"
                  >
                  <span slot="noResult"
                    >{{ $t('seller.sales.text_2620') }}
                    </span>
                  </multiselect>
              </b-form-group>
            </b-col>          
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-3" @click="cancel">
        {{ $t('client.text_51') }}
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit">
        {{ $t('client.text_52') + ' ' + $t("side.text_1956")}}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  props: {
    value: {
      type: Number,
    },
  },
   
  components: {
    Multiselect,
  },
  
  data() {
    return {
      data: {
        date_in: null,
        date_out: null,       
      },
      dados: [],
        options: [
        { id: "SALE", name: this.$t("views.seller.sales.export_transactions.text_100") },
        { id: "WITHDRAW", name: this.$t("views.seller.sales.export_transactions.text_101") },
        { id: "WITHDRAW_FEE", name: this.$t("views.seller.sales.export_transactions.text_102") },
        { id: "REFUNDED", name: this.$t("views.seller.sales.export_transactions.text_103") },
        { id: "CHARGEBACK", name: this.$t("views.seller.sales.export_transactions.text_104") },
        { id: "REPROCESSED", name: this.$t("views.seller.sales.export_transactions.text_105")},
        { id: "ANTECIPATION", name: this.$t("views.seller.sales.export_transactions.text_106") },
        { id: "ANTECIPATION_BALANCE", name: this.$t("views.seller.sales.export_transactions.text_107") },
        { id: "ANTECIPATION_FEE", name: this.$t("views.seller.sales.export_transactions.text_108") },
        { id: "ANTECIPATION_REMAINING", name: this.$t("views.seller.sales.export_transactions.text_109") },
        { id: "SHIPPING", name: this.$t("views.seller.sales.export_transactions.text_110") },
        { id: "AMBASSADOR_SALE", name: this.$t("views.seller.sales.export_transactions.text_111") },
        { id: "AMBASSADOR_REFUNDED", name: this.$t("views.seller.sales.export_transactions.text_112") },
        { id: "AMBASSADOR_CHARGEBACK", name: this.$t("views.seller.sales.export_transactions.text_113") },
        { id: "INDICATION_SALE", name: this.$t("views.seller.sales.export_transactions.text_116") },
        { id: "INDICATION_CHARGEBACK", name: this.$t("views.seller.sales.export_transactions.text_117") },
        { id: "INDICATION_REFUNDED", name: this.$t("views.seller.sales.export_transactions.text_118") }
      ],
     
    };
  },
  methods: {

    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          //adiciona os filtros no vuex     
          let statements_options = null;
          if(this.dados.length > 0){
            statements_options = this.dados.map((item) => item.id).join(",");
          }
          this.$store.commit("setStatementsOptions", statements_options);
          

          this.$emit("filter", this.data);
          this.$bvModal.hide("account-statements-filters");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/variables.scss";

.btn-green {
  display: inline-block;
  border-radius: 5px;
  padding: 7px 20px;
  border: 1px $base-color solid;
  width: auto;
  color: #fff;
  background: $base-color;
  cursor: pointer;

  &:hover {
    background: #fff;
    color: $base-color;
    border: 1px $base-color solid;
  }
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
</style>