<template>
  <b-container class="container-table mb-5">
    <div class="header-table d-flex">
      <div class="d-flex">
        <!-- Export Button -->
        <div class="btns-flex" @click="openExport">
          <b-button class="btn-table" :title="$t('views.seller.statements.export_anticipations')">
            <img src="@/assets/img/icons/download.svg"/>
          </b-button>
          <span class="filter">{{ $t('views.seller.statements.export_anticipations') }}</span>
        </div>
      </div>
    </div>
    <!-- Table Header -->
    <b-row class="Table-header mb-1">
      <b-col md="1" class="d-none d-md-block">#ID</b-col>
      <b-col md="2" class="d-none d-md-block">{{ $t('views.seller.statements.created_at') }}</b-col>
      <b-col md="2" class="d-none d-md-block">{{ $t('views.seller.statements.gross_value') }}</b-col>
      <b-col cols="4" md="1" class="d-none d-md-block">{{ $t('views.seller.statements.fee') }}</b-col>
      <b-col cols="5" md="2">{{ $t('views.seller.statements.antecipated_value') }}</b-col>
      <b-col cols="4" md="2">{{ $t('views.seller.statements.processing_date') }}</b-col>
      <b-col cols="3" md="1">{{ $t('views.seller.statements.view') }}</b-col>
    </b-row>

    <!-- Table Body -->
    <template v-if="!loading && antecipationResponse.length > 0">
      <b-row
        v-for="item in antecipationResponse"
        :key="item.id"
        class="Table-body"
      >
        <!-- Id -->
        <b-col md="1" class="d-none d-md-block">
          #{{ item.id }}
        </b-col>
        <!-- Creation Date -->
        <b-col md="2" class="d-none d-md-block">
          {{ item.created_at | datetime }}
        </b-col>
        <!-- Amount Gross -->
        <b-col md="2" class="d-none d-md-block">
          {{ isHeaven ? '$' : 'R$' }}
          {{ formatMoney(item.amount) }}
        </b-col>
        <!-- Fee -->
        <b-col cols="4" md="1" class="d-none d-md-block">
          {{ isHeaven ? '$' : 'R$' }}
          {{ formatMoney(item.fee) }}
        </b-col>
        <!-- Anticipation Value -->
        <b-col cols="5" md="2">
          {{ isHeaven ? '$' : 'R$' }}
          {{ formatMoney(item.total) }}
        </b-col>
        <!-- Date of Processement -->
        <b-col cols="4" md="2">
          {{ item.updated_at | datetime }}
        </b-col>
        <!-- Actions -->
        <b-col cols="3" md="1">
          <div class="Table-body-action"
            @click="openAntecipation(item.id)"
          >
            <img src="@/assets/img/icons/eye.svg" />
          </div>
        </b-col>
      </b-row>

    </template>

    <!-- Loading -->
    <div v-if="loading && antecipationResponse.length == 0"
      class="Table-body py-4 d-flex justify-content-center align-items-center"
    >
      <b-spinner variant="dark" label="Spinning"></b-spinner>
    </div>
    <!-- Feedback Menssage -->
    <b-row v-if="!loading && antecipationResponse.length == 0"
      class="Table-body justify-content-center"
    >
      <p class="nao-encontrado">{{ $t('views.seller.statements.no_antecipation_found') }}</p>
    </b-row>
    
    <Export 
      v-if="only_anticipations" 
      :only_anticipations="only_anticipations"
      @onHide="only_anticipations = !only_anticipations"
      @show-toast="showToast"
    />
  </b-container>
</template>

<script>
import Money from "@/mixins/money";
import RightPreview from "@/components/Seller/Statements/rightPreview";
import Filters from "@/components/Seller/Statements/modals/filters";
import Export from "@/components/Seller/Statements/modals/export";

import AntecipationService from "@/services/resources/AntecipationService";
const serviceAntecipation = AntecipationService.build();

export default {
  name: "TableAnticipations",
  components: { RightPreview, Export },
  mixins: [Money],
  props: {
    tabIndex: {
      required: true,
      type: Number,
      default: 0
    },
    updateAnticipations: {
      required: true,
      type: Boolean,
      default: false
    },
    currency: {
      required: true,
      type: Object,
      default: () => ({
        id: 1,
        name: "Real Brasileiro",
        currency: "BRL",
        currency_symbol: "R$"
      })
    }
  },
  watch: {
    updateAnticipations() {
      if(this.updateAnticipations && this.tabIndex === 2) {
        this.pagination.currentPage = 1
        this.pagination.perPage = 10
        this.fetchAnticipations(this.currency.id)
        this.$emit('reset-update-anticipations')
      }
    }
  },
  data() {
    return {
      only_anticipations: false,
      antecipationResponse: [],
      loading: false,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 15
      },
      permite_antecipacao: false
    }
  },
  computed: {
    user() {
      return this.$store.getters.getProfile
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    openAntecipation(id_antecipation) {
      const filter = {
        antecipation_group_id: id_antecipation
      }
      this.$emit('open-antecipation', 0, filter)
    },
    fetchAnticipations(currency_id) {
      this.loading = true
      this.antecipationResponse = []

      serviceAntecipation
        .search({ currency_id })
        .then((response) => {
          this.antecipationResponse = response;
          this.$emit('fetch-reports', this.currency.id)
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },
    openExport() {
      this.$emit("changeOnlyAnticipationsValue");
      this.only_anticipations = true;
      setTimeout(()=>{
        this.$bvModal.show("statements-export");
      }, 20);
    },
    showToast(toastOptions) {
      this.$bvToast.toast(toastOptions.message, {
        title: toastOptions.title,
        variant: toastOptions.variant,
        autoHideDelay: toastOptions.autoHideDelay,
        appendToast: toastOptions.appendToast,
      });
    },
  }
}
</script>

<style scoped lang="scss">
.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}
.btns-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .header-table {
    display: grid;
    grid-template-columns: 50px 1fr;
  }
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: rgba(0, 148, 136, 0.1);
}
.btn-table:first-child:active {
  background: rgba(0, 148, 136, 0.1);
  border: none;
}
.btn-table.heaven:first-child {
  background: $base-color-heaven-light;
}
.btn-table.heaven:first-child:active {
  background: $base-color-heaven-light;
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}
.input-busca {
  width: 350px;
  height: 60px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
  margin-bottom: 0px !important;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}
@media screen and (max-width: 768px) {
  .input-busca {
    width: 100%;
  }
}
.search {
  position: absolute;
  left: 22px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 1;
}
.inputSearch {
  position: relative;
}
.filter {
  font-weight: 600;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .filter {
    display: none;
  }
  .Table-body {
    padding: 15px;
  }

  header {
    display: block;
  }
  .item-valor {
    text-align: left;
    margin: 0 !important;
    max-width: 270px;
  }
  .item-valor + .item-valor {
    margin-top: 15px !important;
  }
  .item-valor p {
    font-size: 18px;
  }
}
.link-venda {
  font-size: 12px;
  color: #2133d2;
  font-weight: 600;
}
.limpa-filtro {
  font-size: 13px;
  max-width: 60%;
}
.limpa-filtro span {
  color: #81858e;
}
.limpa-filtro a {
  color: #2133d2;
}
</style>