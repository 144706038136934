<template>
  <div>
    <BaseModal
      :name="modalName"
      size="lg"
      :title="$t('seller.statements.modals.bank_account.text_796')"
      @hidden="resetFields"
      @shown="fetchBankAccount"
    >
      <b-row>
        <b-col cols="12">
          <!-- Loading -->
          <div class="d-flex justify-content-center" v-if="loading">
            <b-spinner label="Loading..."></b-spinner>
          </div>

          <b-form
            novalidate
            v-if="!loading &&
              (status_validation.status == 'APPROVED' ||
              (user.company ? checkStatus(user.company.documents, 'APPROVED').length > 0 : false))
            "
          >
            <!-- National Form -->
            <b-row>
              <!-- Bank Code -->
              <b-col cols="12" sm="12" md="12" lg="3" class="mb-3">
                <b-form-group class="custom-margin" :label="$t('seller.statements.modals.bank_account.text_2710')" label-for="bank_number">
                  <b-form-input
                    id="bank_number"
                    name="bank_number"
                    v-model="bank_number"
                    v-validate="'required'"
                    autocomplete="off"
                    placeholder="- - -"
                    type="text"
                    :disabled="disabledBankFilds"
                    :class="{'heaven':isHeaven}"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('bank_number')">
                    {{ $t('seller.statements.modals.bank_account.text_797') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Bank Name -->
              <b-col cols="12" sm="12" md="12" lg="9" class="banco mb-3">
                <b-form-group class="w-100" :label="$t('seller.statements.modals.bank_account.text_2711')" label-for="bank_name">
                  <b-form-input
                    id="bank_name"
                    name="bank_name"
                    v-model="bank_name"
                    v-validate="'required'"
                    autocomplete="off"
                    :placeholder="$t('seller.statements.modals.bank_account.text_2712')"
                    type="text"
                    :disabled="disabledBankFilds"
                    :class="{'heaven':isHeaven}"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('bank_name')">
                    {{ $t('seller.statements.modals.bank_account.text_798') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <div class="search_bank" v-b-modal.bank-account-bank-list>
                  <img src="@/assets/img/icons/search.svg" class="" />
                  {{ $t('seller.statements.modals.bank_account.text_799') }}
                </div>
              </b-col>
              <!-- Agency -->
              <b-col cols="12" sm="12" md="6" class="mb-3">
                <b-form-group class="custom-margin" :label="$t('seller.statements.modals.bank_account.text_2713')" label-for="agency">
                  <b-form-input
                    id="agency"
                    name="agency"
                    v-model="agency"
                    v-validate="'required'"
                    v-mask="'#########'"
                    autocomplete="off"
                    placeholder="0000"
                    type="text"
                    :class="{'heaven':isHeaven}"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('agency')">
                    {{ $t('seller.statements.modals.bank_account.text_800') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- DV -->
              <b-col cols="12" sm="12" md="6" class="mb-3">
                <b-form-group label="DV (opcional)" label-for="agency_dv">
                  <b-form-input
                    id="agency_dv"
                    v-mask="'#'"
                    v-model="agency_dv"
                    autocomplete="off"
                    type="text"
                    :class="{'heaven':isHeaven}"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <!-- Account Number -->
              <b-col cols="12" sm="12" md="6" class="mb-3">
                <b-form-group class="custom-margin" :label="$t('seller.statements.modals.bank_account.text_2714')" label-for="account">
                  <b-form-input
                    id="account"
                    name="account"
                    v-model="account"
                    v-validate="'required'"
                    v-mask="'############'"
                    autocomplete="off"
                    placeholder="00000"
                    type="text"
                    :class="{'heaven':isHeaven}"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('account')">
                    {{ $t('seller.statements.modals.bank_account.text_801') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- DV -->
              <b-col cols="12" sm="12" md="6" class="mb-3">
                <b-form-group label="DV" label-for="account_dv">
                  <b-form-input
                    id="account_dv"
                    name="account_dv"
                    v-model="account_dv"
                    v-validate="'required'"
                    v-mask="'#'"
                    autocomplete="off"
                    placeholder="0"
                    type="text"
                    :class="{'heaven':isHeaven}"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('account_dv')">
                    {{ $t('seller.statements.modals.bank_account.text_802') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Account Type -->
              <b-col cols="12" sm="12" md="6" class="mb-3">
                <b-form-group class="custom-margin" label="Tipo de conta" label-for="type">
                  <b-form-select
                    v-model="type"
                    name="type"
                    :options="accountTypes"
                    v-validate="'required'"
                    :class="{'heaven':isHeaven}"
                  ></b-form-select>
                  <b-form-invalid-feedback :state="!errors.has('type')">
                    {{ $t('seller.statements.modals.bank_account.text_2715') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Modality -->
              <b-col cols="12" sm="12" md="6">
                <b-form-group :label="$t('seller.statements.modals.bank_account.text_2716')" label-for="modality">
                  <b-form-select
                    v-model="modality"
                    name="modality"
                    :options="accountModality"
                    v-validate="'required'"
                    :class="{'heaven':isHeaven}"
                  ></b-form-select>
                  <b-form-invalid-feedback :state="!errors.has('modality')">
                    {{ $t('seller.statements.modals.bank_account.text_803') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>

          <!-- Feedback Menssages -->
          <div
            class="sem-docs"
            v-if="
              !loading &&
              status_validation.status != 'APPROVED' &&
              (user.company
                ? (checkStatus(user.company.documents, 'DISAPPROVED').length ||
                    checkStatus(user.company.documents, 'REQUESTED').length ||
                    !user.company.documents.length) &&
                  !checkStatus(user.company.documents, 'APPROVED').length
                : true)
            "
          >
            <!-- Menssages of Success -->
            <div v-if="status_validation.status == 'REQUESTED'"
              class="aguardando-analise"
            >
              <p>
                {{ $t('seller.statements.modals.bank_account.text_804') }}
              </p>
              <p>
                {{ $t('seller.statements.modals.bank_account.text_805') }}
              </p>
            </div>
            <!-- Menssages of Error -->
            <div v-else>
              <p>
                {{ $t('seller.statements.modals.bank_account.text_806') }}
              </p>
              <p>
                {{ $t('seller.statements.modals.bank_account.text_807') }}
              </p>
              <BaseButton variant="black" @click="initVerification"
                >{{ $t('seller.statements.modals.bank_account.text_808') }}</BaseButton
              >
            </div>
          </div>

        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <BaseButton
            :class="{'heaven':isHeaven}"
            variant="primary"
            @click="validateForms"
            :disabled="
              loading ||
              (status_validation.status != 'APPROVED' &&
                (user.company
                  ? !checkStatus(user.company.documents, 'APPROVED').length
                  : true))
            "
          >
            {{ !update ? $t('seller.statements.modals.bank_account.text_813') : $t('seller.statements.modals.bank_account.text_814') }}
          </BaseButton>
        </b-col>
      </b-row>

      <h5 class="mt-3 mb-4 titulo-contas" v-if="!loading">
        {{ $t('seller.statements.modals.bank_account.text_809') }}
      </h5>

      <!-- Saved Bank Accounts Table -->
      <template>
        <div v-if="!loading && bankAccounts.length > 0" class="container-scrollbar">
          <!-- Saved Bank Accounts Table Header -->
          <b-row class="header-table-contas">
            <b-col cols="12" md="5" class="d-none d-md-block"> {{ $t('seller.statements.modals.bank_account.text_2717') }} </b-col>
            <b-col cols="12" md="2" class="d-none d-md-block"> {{ $t('seller.statements.modals.bank_account.text_2713') }} </b-col>
            <b-col cols="12" md="2" class="d-none d-md-block"> {{ $t('seller.statements.modals.bank_account.text_2714') }} </b-col>
            <b-col cols="12" md="3" class="d-none d-md-block"> {{ $t('seller.statements.modals.bank_account.text_840') }} </b-col>
            <b-col cols="12" md="3" class="d-md-none d-block">
              {{ $t('seller.statements.modals.bank_account.text_810') }}
            </b-col>
          </b-row>
          <!-- Saved Bank Accounts List -->
          <b-row
            v-for="item in bankAccounts"
            :key="item.id"
            class="BankAccount-item"
            data-anima="top"
          >
            <b-col cols="12" md="5">
              <div v-if="item.bank_name">
                • {{ item.bank_number }} -

                  {{
                    item.bank_name.length > 30
                      ? item.bank_name.substr(0, 30) + "..."
                      : item.bank_name
                  }}
              </div>
              <div v-else>
                • {{ item.bic_swift }}
              </div>
              <p class="mt-md-2">
                <span class="d-inline">• {{ $t('seller.statements.modals.bank_account.text_2719') }} - </span>
                {{ item.cpf_cnpj }}
              </p>
            </b-col>
            <b-col cols="12" md="2">
              <span class="d-md-none d-inline">• {{$t('seller.statements.modals.bank_account.text_2713') }} - </span>
              {{ item.agency }}
            </b-col>
            <b-col cols="12" md="2">
              <span class="d-md-none d-inline">• {{ $t('seller.statements.modals.bank_account.text_2714') }} - </span>
              {{ item.account }}-{{ item.account_dv }}
            </b-col>
            <b-col cols="12" md="3" v-if="item.account_type">
              <span class="d-md-none d-inline">• {{ $t('seller.statements.modals.bank_account.text_840') }} - </span>
              {{ $t(`seller.statements.modals.bank_account.${item.account_type}`) }}
            </b-col>
            <!-- <b-col cols="12" md="3" class="d-flex">
              <b-button
                type="button"
                class="btn-novo btn-editar"
                @click="loadEdit(item)"
                >{{ $t('seller.statements.modals.bank_account.text_2720') }}
              </b-button>
            </b-col> -->
          </b-row>
        </div>
        <p class="info-remove mb-4">
          {{ $t('seller.statements.modals.bank_account.text_811') }}
        </p>
        <!-- Loading -->
        <div v-if="loading && bankAccounts.length === 0"
          class="table-body py-4 d-flex justify-content-center align-items-center"
        >
          <b-spinner variant="dark" label="Spinning"></b-spinner>
        </div>
        <!-- Feedback Menssage -->
        <b-row v-if="!loading && bankAccounts.length === 0"
          class="table-body justify-content-center mb-3"
        >
          <p class="nao-encontrado">{{ $t('seller.statements.modals.bank_account.text_812') }}</p>
        </b-row>
      </template>

      <!-- Footer -->
      <template v-slot:footer="{ cancel }">
        <BaseButton
          variant="link-info"
          @click="cancel"
          class="mr-3"
          :disabled="loading"
        >
          {{ $t('seller.statements.modals.bank_account.text_2721') }}
        </BaseButton>
      </template>
    </BaseModal>

    <!-- Modal Bank List -->
    <ModalBankAccountBankList @select="selectBank" />

    <ModalValidation @close="getStatusValidation" />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

import BankAccountService from "@/services/resources/BankAccountService";
import ModalBankAccountRemove from "./bankAccountRemove";
import ModalBankAccountBankList from "./bankAccountBankList";
import ValidationService from "@/services/resources/ValidationService";

const serviceValidation = ValidationService.build();
const service = BankAccountService.build();

export default {
  name: 'ModalBankAccount',
  components: {
    ModalBankAccountRemove,
    Multiselect,
    ModalBankAccountBankList
  },
  props: {
    value: {
      type: Number,
    },
    modalName: {
      required: false,
      type: String || Null,
      default: 'bank-account'
    }
  },
  data() {
    return {
      status_validation: {
        status: "",
        observation: "",
      },
      agency: null,
      agency_dv: null,
      account: null,
      account_dv: null,
      modality: null,
      type: null,
      name: null,
      accountModality: [
        { value: null, text: this.$t('seller.statements.modals.bank_account.text_837') },
        { value: "CURRENT", text: this.$t('seller.statements.modals.bank_account.text_838') },
        { value: "SAVINGS", text: this.$t('seller.statements.modals.bank_account.text_839') },
      ],
      accountTypes: [{ value: null, text: this.$t('seller.statements.modals.bank_account.text_840') }],
      bankAccounts: [],
      bank_account_id: -1,
      submit: false,
      loading: false,
      update: false,
      user: {
        documents: [],
        company: {
          documents: [],
        },
      },
      disabledBankFilds: true,
    }
  },
  computed: {
    userId() {
      return this.$store.getters.getProfile.id;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    bank_number: {
      get() { return this.$store.getters['getBankNumber'] },
      set(value) { this.$store.dispatch('updateBankNumber', value) }
    },
    bank_name: {
      get() { return this.$store.getters['getBankName'] },
      set(value) { this.$store.dispatch('updateBankName', value) }
    },
  },
  methods: {
    checkStatus(docs, status) {
      if (docs.length) {
        return docs.filter((item) => item.status == status);
      } else return [];
    },
    initVerification() {
      this.$bvModal.show("identity-validation");
    },
    // editar conta bancária não será possivel no momento, essa função não esta sendo utilizada
    loadEdit(item) {
      document.querySelector("#bank-account").scrollTo({ top: 0, behavior: "smooth" });

      if (document.querySelector("#bank_number")) {
        document.querySelector("#bank_number").focus()
      }
      this.bank_number = item.bank_number
      this.bank_name = item.bank_name
      this.agency = item.agency
      this.agency_dv = item.agency_dv
      this.account = item.account
      this.account_dv = item.account_dv
      this.name = item.name
      this.bank_account_id = item.id
      this.type = item.account_type
      this.modality = item.type

      this.update = true;
    },
    selectBank(item) {
      this.bank_number = item.code
      this.bank_name = item.name
    },
    openConfirmRemove(id) {
      this.bank_account_id = id
      this.$bvModal.show("bank-account-remove")
    },
    resetFields() {
      this.bank_number = null
      this.bank_name = null
      this.agency = null
      this.agency_dv = null
      this.account = null
      this.account_dv = null
      this.type = null
      this.modality = null
      this.name = null
      this.bank_account_id = -1
      this.update = false
    },
    /**
     * Triggered when user press submit button, will check which form is being filled. If it's a national, will validate it, if valid, will save the bank account data, if invalid, will return an error message.
     * @return no return
     * @params no params
     * @author Sandro Souza
     */
    validateForms() {
      // enables bank fields so they can be validated
      this.disabledBankFilds = false
      setTimeout(() => {
        this.$validator.validateAll().then((result) => {
          if (result) {
            let data = {
              bank_number: this.bank_number,
              bank_name: this.bank_name,
              agency: this.agency,
              agency_dv: this.agency_dv,
              account_dv: this.account_dv,
              account: this.account,
              type: this.modality,
              account_type: this.type,
              name: this.name,
              user_id: this.userId,
            }
            if (!this.agency_dv) {
              delete data.agency_dv
            }
            this.onSubmit(data)
          }
          else {
            document.querySelector("#bank-account").scrollTo({ top: 0, behavior: "smooth" })
            this.$bvToast.toast(this.$t('seller.statements.modals.bank_account.text_841'), {
              title: this.$t('seller.statements.modals.bank_account.text_2722'),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true
            })
          }
          this.disabledBankFilds = true
        })
      }, 500)
    },
    onSubmit(data) {
      this.submit = true
      this.loading = true
      if (!this.update) {
        service
          .create(data)
          .then(() => {
            this.submit = false
            this.loading = false
            this.$bvToast.toast(this.$t('seller.statements.modals.bank_account.text_815'), {
              title: this.$t('seller.statements.modals.bank_account.text_2722'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true
            })

            this.resetFields()
            this.$emit("close")
            this.$bvModal.hide(this.modalName)
          })
          .catch(err => console.error(err))
          .finally(() => {
            this.submit = false
            this.loading = false
          })
      } else {
        // On editing
        data.id = this.bank_account_id
        if(data.id === -1) data.id = data.bank_account_id
        if(data.id === undefined) {
          data.name = JSON.parse(localStorage.user).name
        }
        service
          .update(data)
          .then(() => {
            this.submit = false;
            this.loading = false;
            this.$bvToast.toast(this.$t('seller.statements.modals.bank_account.text_816'), {
              title: this.$t('seller.statements.modals.bank_account.text_2722'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true
            })

            this.resetFields()
            this.$emit("close")
            this.$bvModal.hide(this.modalName)
          })
          .catch(err => console.error(err))
          .finally(() => {
            this.submit = false
            this.loading = false
          })
      }
    },
    fetchBankAccount() {
      this.loading = true
      this.getStatusValidation()
      let bankAccounts = []

      service
        .search()
        .then((response) => {
          response.forEach(bankAcc => {
            bankAccounts.push(bankAcc)
          })
          bankAccounts = bankAccounts.reverse();
        })
        .catch(err => console.error(err))
        .finally(() => {
          this.bankAccounts = bankAccounts
          this.loading = false
        })
    },
    getStatusValidation() {
      this.loading = true
      let data = { id: "status" }

      serviceValidation
        .read(data)
        .then(response => {
          this.status_validation = response
          if (!this.status_validation.status) {
            this.status_validation = {
              status: "INITIATED",
              observation: ""
            }
          }
          var accountType = this.accountTypes
          var result = accountType.filter((x) => x.value === "PERSONAL")
          if(!this.accountTypes.some(a => a.text === this.$t('seller.statements.modals.bank_account.text_842'))) {
            if (this.status_validation.status == "APPROVED") {
              if (!result.length) {
                this.accountTypes.push({
                  value: "PERSONAL",
                  text: this.$t('seller.statements.modals.bank_account.text_842')
                })
              }
            } else {
              if (!result.length) {
                this.accountTypes.push({
                  value: "PERSONAL",
                  text: this.$t('seller.statements.modals.bank_account.text_842'),
                  disabled: true
                })
              }
            }
          }
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },
  },
  mounted() {
    this.loading = true

    this.$store
      .dispatch("userRequest")
      .then((response) => {
        this.user = response
        this.fetchBankAccount()

        var accountType = this.accountTypes
        var result = accountType.filter((x) => x.value === "PERSONAL")
        if(this.user.company && this.checkStatus(this.user.company.documents, "APPROVED").length) {
          if (!result.length) {
            this.accountTypes.push({
              value: "BUSINESS",
              text: this.$t('seller.statements.modals.bank_account.text_818')
            })
          }
        } else {
          if (!result.length) {
            this.accountTypes.push({
              value: "BUSINESS",
              text: this.$t('seller.statements.modals.bank_account.text_818'),
              disabled: true
            })
          }
        }
      })
      .finally(() => { this.loading = false })
  }
}
</script>

<style lang="scss" scoped>
.BankAccount-item {
  border-top: 1px #ededf0 solid;
  padding: 15px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.banco {
  display: flex;
  align-items: center;
}
.banco .search_bank {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: 30px;
  outline: none;
  cursor: pointer;
  margin-top: 30px;
  color: #333;
  font-weight: 600;
}
.search_bank {
  width: 240px;
}
.search_bank img {
  margin-right: 15px;
}
.titulo-contas {
  font-weight: 600;
  font-size: 16px;
}
.header-table-contas {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}
.btn-novo {
  padding: 5px 10px;
  padding: 4px 8px;
  font-size: 13px;
  font-weight: 600;
  margin-right: 10px;
  border: none;
}
.btn-editar {
  background: transparent;
  color: #2133d2;
}

@media screen and (max-width: 768px) {
  .banco {
    display: block;
  }
  .banco .search_bank {
    margin: 0;
    padding: 0;
    margin-top: 15px;
  }
}
.info-documento,
.sem-docs p,
.info-remove {
  margin: 0;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  line-height: 1.5;
}
.info-remove {
  margin-top: 15px;
}
.sem-docs {
  padding: 10px 0;
}
.sem-docs p {
  font-size: 14px;
}
.sem-docs p + p {
  margin-top: 7px;
}
.sem-docs button {
  margin: 20px 0;
}
.aguardando-analise {
  background: #2133d210;
  padding: 20px;
  border-radius: 5px;
}
.aguardando-analise p {
  color: #2133d2;
}

.custom-margin {
  margin-right: 16px !important;
}

@media only screen and (max-width: 768px) {
  .custom-margin {
    margin-right: 0px !important;
  }
}

.container-scrollbar {
  max-height: 457px;
  overflow-x: auto;
}
.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}
</style>
