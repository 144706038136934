<template>
  <div class="container">
    <div class="d-flex justify-content-between align-items-end">
      <BaseHeader :title="$t('views.seller.statements.financial_statement')"/>
      <!-- Filter Currency -->
      <!-- <SelectCurrency v-show="isHeaven"
        :currency="currency"
        @update-coin="updateCoin"
      /> -->
    </div>

    <div class="top-header">
      <div class="box-valores" data-anima="top">
        <!-- Avaliable Balance -->
        <div class="item-valor small-item" id="saldo-disponivel">
          <img src="@/assets/img/icons/money-free.svg" />
          <div class="item-money">
            <span>{{ $t('views.seller.statements.balance_available') }}</span>
            <p v-if="currency" :class="{ visibled: !loading_money }" class="money">
              {{ isHeaven ? '$': 'R$' }}
              {{ available ? formatMoney(available) : "0,00" }}
            </p>
          </div>
        </div>
        <b-tooltip
          target="saldo-disponivel"
          :title="$t('views.seller.statements.tooltip1')"
        />
        <!-- Blocked Balance -->
        <div class="item-valor small-item" id="saldo-block">
          <img src="@/assets/img/icons/money-block.svg" />
          <div class="item-money">
            <span>{{ $t('views.seller.statements.blocked_balance') }}</span>
            <p  v-if="currency" :class="{ visibled: !loading_money }" class="money">
              {{ isHeaven ? '$': 'R$' }}
              {{ block ? formatMoney(block) : "0,00" }}
            </p>
          </div>
        </div>
        <b-tooltip
          target="saldo-block"
          :title="$t('views.seller.statements.tooltip2')"
        />
        <!-- Anticipation Balance -->
        <div id="saldo-antecipacao" class="item-valor">
          <img
            v-if="permite_antecipacao && statusAntecipation !== 'REQUESTED'"
            src="@/assets/img/icons/money-anticipation.svg"
          />
          <div class="item-money" v-if="permite_antecipacao && statusAntecipation !== 'REQUESTED'">
            <span>{{ $t('views.seller.statements.available_for_antecipation') }}</span>
            <p v-if="currency" :class="{ visibled: !loading_money }" class="money">
              {{ isHeaven ? '$': 'R$' }}
              {{ antecipation ? formatMoney(antecipation) : "0,00" }}
            </p>
          </div>
        </div>
        <b-tooltip
          target="saldo-antecipacao"
          :title="$t('views.seller.statements.tooltip3')"
        />
        <!-- Wallet Balance -->
        <div class="item-valor mr-3" id="saldo-carteira">
          <img src="@/assets/img/icons/money-wallet.svg" />
          <div class="item-money">
            <span> {{ $t('views.seller.statements.my_wallet') }} </span>
            <p v-if="currency" :class="{ visibled: !loading_money }" class="money">
              {{ isHeaven ? '$': 'R$' }}
              {{ total ? formatMoney(total) : "0,00" }}
            </p>
          </div>
        </div>
        <b-tooltip
          target="saldo-carteira"
          :title="$t('views.seller.statements.tooltip4')"
        />
      </div>
      <!-- Withdraw Money -->
      <BaseButton :disabled="loading || loadingKyc" :loading="loading || loadingKyc" variant="outline-info" @click="openModal" class="btn-sacar">
        {{ $t('views.seller.statements.withdraw_money') }}
      </BaseButton>
    </div>

    <b-tabs
      v-model="tabIndex"
      nav-class="TabHeader"
      :active-nav-item-class="isHeaven ? 'TabHeader-heaven--active' : 'TabHeader--active'"
      :no-nav-style="true"
      @activate-tab="tabSelect"
    >
      <b-tab :title="$t('views.seller.statements.transactions')">
        <TableTransactions
          :tab-index="tabIndex"
          :all_transactions="all_transactions"
          :update-statements="updateStatements"
          :filter-statements="filterStatements"
          :currency="currency"
          @open-right-preview="openPanel"
          @fetch-reports="fetchReports"
          @fetch-last-antecipation="fetchLastAntecipation"
          @reset-update-statements="updateStatements = false"
          @changeAllTransactionsValue="all_transactions = !all_transactions"
        />
      </b-tab>
      <b-tab :title="$t('views.seller.statements.withdraws')">
        <TableWithdraw
          :tab-index="tabIndex"
          :only_withdraw="only_withdraw"
          :update-withdrawals="updateWithdrawals"
          :currency="currency"
          @fetch-reports="fetchReports"
          @reset-update-withdrawals="updateWithdrawals = false"
          @changeOnlyWithdrawValue="only_withdraw = !only_withdraw"
        />
      </b-tab>
      <!-- <b-tab v-if="permite_antecipacao" :title="$t('views.seller.statements.antecipations')"> -->
      <b-tab :title="$t('views.seller.statements.antecipations')">
        <TableAnticipations
          :tab-index="tabIndex"
          :only_anticipations="only_anticipations"
          :update-anticipations="updateAnticipations"
          :currency="currency"
          @open-antecipation="openAntecipation"
          @fetch-reports="fetchReports"
          @reset-update-anticipations="updateAnticipations = false"
          @changeOnlyAnticipationsValue="only_anticipations = !only_anticipations"
        />
      </b-tab>
    </b-tabs>

    <!-- modals -->
    <Withdraw
      :total="total"
      :permite_antecipacao="permite_antecipacao"
      :antecipation_fee="antecipation_fee"
      :status_antecipation="statusAntecipation"
      :last_antecipation="LastAntecipation"
      :last-antecipation-currency-symbol="lastAntecipationCurrencySymbol"
      :loadingKyc="loadingKyc"
      @close="closeModalWithdraw"
      @pending="fetchLastAntecipation"
      @stop-loading="loadingKyc = false"
    />
    <ModalBankAccount />
    <DocumentNumber @close="openModal" />
    <RightPreview ref="statementsPreview" />
    <CallEvaluation/>
    <div id="toast-container"></div>
  </div>
</template>
<script>
import Money from "@/mixins/money";
import Withdraw from "@/components/Seller/Statements/modals/withdraw";
import DocumentNumber from "@/components/Seller/Statements/modals/documentNumber";
import RightPreview from "@/components/Seller/Statements/rightPreview";
import ModalBankAccount from "@/components/Seller/Statements/modals/bankAccount";
import TableTransactions from "@/components/Seller/Statements/tableTransactions";
import TableWithdraw from "@/components/Seller/Statements/tableWithdraw";
import TableAnticipations from "@/components/Seller/Statements/tableAnticipations";
import SelectCurrency from "@/components/SelectCurrency.vue";

import DashboardService from "@/services/resources/DashboardService";
import UserSettingsService from "@/services/resources/UserSettingsService";
import AntecipationService from "@/services/resources/AntecipationService";

const serviceDashboard = DashboardService.build();
const serviceUserSettings = UserSettingsService.build();
const serviceAntecipation = AntecipationService.build();

export default {
  name: "Extrato",
  components: {
    Withdraw,
    RightPreview,
    ModalBankAccount,
    DocumentNumber,
    TableTransactions,
    TableWithdraw,
    TableAnticipations,
    SelectCurrency
  },
  mixins: [Money],
  data() {
    return {
      only_anticipations: false,
      only_withdraw: false,
      all_transactions: false,
      antecipation_id: -1,
      loading_money: false,
      loading: false,
      tabIndex: 0,
      statement_id: -1,
      statusAntecipation: null,
      LastAntecipation: null,
      lastAntecipationCurrencySymbol: "R$",
      updateStatements: false,
      updateWithdrawals: false,
      updateAnticipations: false,
      filterStatements: {},
      permite_antecipacao: false,
      antecipation_fee: 0,
      currency: {
        id: 1,
        name: "Real Brasileiro",
        currency: "BRL",
        currency_symbol: "R$"
      },
      validateStatemants: true,
      // Report
      total: 0,
      available: 0,
      block: 0,
      antecipation: 0,
      loadingKyc: true
    }
  },
  computed: {
    userId() {
      return this.$store.getters.getProfile.id;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  created(){
    if(this.$store.getters.setPanel) this.$router.push("/dashboard")
  },
  methods: {
    closeModalWithdraw() {
      if(JSON.parse(localStorage.user).country) if(JSON.parse(localStorage.user).country.base_currency) {
        this.currency = JSON.parse(localStorage.user).country.base_currency
      }
      if(this.tabIndex === 0) this.updateStatements = true
      this.fetchReports(this.currency.id)
    },
    fetchUserSettings() {
      let data = {
        id: this.userId || JSON.parse(localStorage.user).id,
      };

      serviceUserSettings.read(data).then((response) => {
        response.forEach((item) => {
          if (item.key == "ANTECIPATION" && item.value == "1") {
            this.permite_antecipacao = true;
          }
          if (item.key == "ANTECIPATION_FEE") {
            this.antecipation_fee = +item.value;
          }
        });
      })
      .catch(err => console.error(err));
    },
    fetchReports(currency_id) {
      this.loading_money = true
      let data = {
        types: ["statements"],
        currency_id
      }

      serviceDashboard
        .search(data)
        .then((response) => {
          this.available = response.statementsAvailable;
          this.total = response.statementsTotal;
          this.block = response.statementsBlock;
          this.antecipation = response.statementsCanBeAntecipated;
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading_money = false })
    },
    fetchLastAntecipation() {
      this.loading = true;
      serviceAntecipation
        .read('/request/info')
        .then((response) => {
          if(response.last_antecipation) {
            if(response.automatic_approve === "1"){
              response.last_antecipation.status = "AUTOMATICALLY_APPROVED"
            }
            if(response.last_antecipation.base_currency) {
              this.lastAntecipationCurrencySymbol = response.last_antecipation.base_currency.currency_symbol
            }
            this.statusAntecipation = response.last_antecipation.status
            this.LastAntecipation = response.last_antecipation
          }
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false });
    },
    openModal() {
      this.$bvModal.show("withdraw-request");
    },
    openPanel(id, antecipation) {
      if (!antecipation) {
        this.statement_id = id;
        this.$router
          .push({ query: { ex_id: this.statement_id } })
          .catch(() => {});
      } else {
        this.antecipation_id = id;
        this.$router
          .push({ query: { antecipation_id: this.antecipation_id } })
          .catch(() => {});
      }

      setTimeout(() => {
        this.$refs.statementsPreview.openPanel(id, antecipation);
        this.loading = false;
      });
    },

    tabSelect(newTabIndex) {
      switch (newTabIndex) {
        case 0:
          if(this.validateStatemants) {
            this.updateStatements = true
          } else this.validateStatemants = true
        break
        case 1: this.updateWithdrawals = true
        break
        case 2: this.updateAnticipations = true
        break
      }
    },
    openAntecipation(newTabIndex, filter) {
      this.validateStatemants = false
      this.filterStatements = filter
      this.tabIndex = newTabIndex;
      this.openPanel(filter.antecipation_group_id, true);
    },
    updateCoin(coin) {
      this.loading_money = true
      this.currency = coin
      if(this.tabIndex === 0) this.updateStatements = true
      if(this.tabIndex === 1) this.updateWithdrawals = true
      if(this.tabIndex === 2) this.updateAnticipations = true
    },
  },
  mounted() {
    if(JSON.parse(localStorage.user).country) if(JSON.parse(localStorage.user).country.base_currency) {
      this.currency = JSON.parse(localStorage.user).country.base_currency
    }

    this.fetchUserSettings();

    if (this.$route.query.withdraw == "true") {
      this.openModal();
    }

    // abrindo venda unica
   
      this.statement_id = this.$route.query.ex_id;
  
  },
};
</script>

<style scoped>
.box-valores {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
}
.box-valores + .box-valores {
  margin-top: 15px;
}
.top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px auto;
}
@media screen and (max-width: 768px) {
  .top-header {
    /* flex-direction: column; */
    display: block;
    margin: 40px 0;
  }
  .top-header .btn-sacar {
    margin-top: 20px;
  }
}
.item-valor {
  display: flex;
  align-items: center;
  gap: 15px;
}
.item-valor span {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
}
.item-valor p {
  font-size: 16px;
  font-weight: 600;
  margin: 3px 0;
}
.filter {
  font-weight: 600;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}
.money {
  background: rgba(0, 0, 0, 0.03);
  width: 100%;
  border-radius: 5px;
  transition: 0.3s;
  color: transparent;
}
.money.visibled {
  background: transparent;
  color: #333;
}
@media screen and (max-width: 768px) {
  .box-valores {
    display: block;
    margin-top: 20px;
  }
  .item-valor {
    text-align: left;
    margin: 0 !important;
    max-width: 270px;
  }
  .item-valor + .item-valor {
    margin-top: 15px !important;
  }
  .item-valor p {
    font-size: 18px;
  }
}
</style>
