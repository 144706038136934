import { render, staticRenderFns } from "./tableTransactions.vue?vue&type=template&id=6bfb4f94&scoped=true&"
import script from "./tableTransactions.vue?vue&type=script&lang=js&"
export * from "./tableTransactions.vue?vue&type=script&lang=js&"
import style0 from "./tableTransactions.vue?vue&type=style&index=0&id=6bfb4f94&scoped=true&lang=scss&"
import style1 from "./tableTransactions.vue?vue&type=style&index=1&id=6bfb4f94&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bfb4f94",
  null
  
)

export default component.exports