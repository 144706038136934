<template>
  <b-container fluid class="container-table">
    <div class="header-table d-flex">
      <div class="d-flex">
        <!-- Export Button -->
        <div class="btns-flex" @click="openExport">
          <b-button class="btn-table" :title="$t('views.seller.statements.export_withdraw')">
            <img src="@/assets/img/icons/download.svg"/>
          </b-button>
          <span class="filter">{{ $t('views.seller.statements.export_withdraw') }}</span>
        </div>
      </div>
    </div>
    <!-- <div class="header-table d-flex">
      <div class="d-flex"> -->
        <!-- Export Button -->
        <!-- <div class="btns-flex" @click="openExport" style="margin-left: 15%;">
          <b-button class="btn-table"  :title="$t('views.seller.statements.export')">
            <img src="@/assets/img/icons/download.svg"/>
          </b-button>
          <span class="filter">{{ $t('views.seller.statements.export') }}</span>
        </div>
      </div>
    </div> -->

    <!-- Table Header -->
    <b-row class="Table-header my-1">
      <b-col cols="2" class="d-none d-md-block">{{ $t('seller.products.modal_response.text_573') }}</b-col>
      <b-col cols="6" md="2">{{ $t('seller.products.metas.text_562') }}</b-col>
      <b-col cols="3" class="d-none d-md-block">{{ $t('seller.statements.modals.bank_account.text_2714') }}</b-col>
      <b-col cols="2" class="d-none d-md-block">{{ $t('views.seller.statements.observation') }}</b-col>
      <b-col cols="6" md="3">{{ $t('client.text_70') }}</b-col>
    </b-row>

    <!-- Table Body -->
    <template v-if="!loading && withdrawals.length > 0">
      <b-row
        v-for="item in withdrawals"
        :key="item.id"
        class="Table-body"
      >
        <!-- Creation Date -->
        <b-col cols="2" class="d-none d-md-block">
          {{ item.created_at | datetime }}
        </b-col>
        <!-- Value -->
        <b-col cols="6" md="2" class="Table-body-price">
          {{ isHeaven ? '$' : 'R$' }}
          {{ formatMoney(item.amount) }}
        </b-col>
        <!-- Bank Account -->
        <b-col cols="3" class="d-none d-md-block">
          {{ item.account ? item.account.account : '' }} - {{ item.account ? item.account.bank_name : '' }}
        </b-col>
        <!-- Observation -->
        <b-col cols="2" class="Table-body-price d-none d-md-block">
          {{ item.observation }}
        </b-col>
        <!-- Status -->
        <b-col cols="6" md="3">
          <span v-if="item.status === 'REQUESTED'" class="icon-blue">
            {{ $t('views.seller.statements.requested') }}
          </span>
          <span v-if="item.status === 'ACCEPTED'" class="icon-grenn">
            {{ $t('views.seller.statements.transferred') }}
          </span>
          <span v-if="item.status === 'DENIED'" class="icon-red">
            {{ $t('views.seller.statements.refused') }}
          </span>
          <span v-if="item.status === 'REFUNDED'" class="icon-warning">
            {{ $t('views.seller.statements.refunded') }}
          </span>
          <span v-if="item.status === 'ANALYZE'" class="icon-blue">
            {{ $t('views.seller.statements.analyze') }}
          </span>
        </b-col>
      </b-row>
    </template>

    <Paginate v-if="withdrawals.length > 0"
      :totalPages="pagination.totalPages"
      :activePage="pagination.currentPage"
      @to-page="toPage"
      @per-page="perPage"
    />

    <!-- Loading -->
    <div v-if="loading && withdrawals.length == 0"
      class="Table-body py-4 d-flex justify-content-center align-items-center"
    >
      <b-spinner variant="dark" label="Spinning"></b-spinner>
    </div>
    <!-- Feedback Menssage -->
    <b-row v-if="!loading && withdrawals.length == 0"
      class="Table-body justify-content-center"
    >
      <p class="nao-encontrado">
        {{ $t('views.seller.statements.no_withdraw_solicitations_found') }}
      </p>
    </b-row>

    <div id="toast-container"></div>

    <!-- <Filters/> -->
    <Export 
      v-if="only_withdraw"
      :only_withdraw="only_withdraw"
      @onHide="only_withdraw = !only_withdraw"
      @show-toast="showToast"
    />
  </b-container>
</template>

<script>
import Paginate from "@/components/shared/Paginate";
import Money from "@/mixins/money";
import Filters from "@/components/Seller/Statements/modals/filters";
import Export from "@/components/Seller/Statements/modals/export";

import WithdrawService from "@/services/resources/WithdrawService";

const serviceWithdraw = WithdrawService.build();

export default {
  name: "TableWithdraw",
  components: {
    Paginate,
    Filters,
    Export
  },
  mixins: [Money],
  props: {
    tabIndex: {
      required: true,
      type: Number,
      default: 0
    },
    updateWithdrawals: {
      required: true,
      type: Boolean,
      default: false
    },
    currency: {
      required: true,
      type: Object,
      default: () => ({
        id: 1,
        name: "Real Brasileiro",
        currency: "BRL",
        currency_symbol: "R$"
      })
    }
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  watch: {
    updateWithdrawals() {
      if(this.updateWithdrawals && this.tabIndex === 1) {
        this.pagination.currentPage = 1;
        this.pagination.perPage = 10;
        this.fetchWithdrawals(this.currency.id);
        this.$emit('reset-update-withdrawals');
      }
    }
  },
  data() {
    return {
      only_withdraw: false,
      loading: false,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 15,
      },
      statement_id: -1,
      total: 0,
      withdrawals: [],
    }
  },
  methods: {
    openFilters() {
      this.$bvModal.show("account-statements-filters");
    },
    openExport() {
      this.$emit("changeOnlyWithdrawValue");
      this.only_withdraw = true;
      setTimeout(()=>{
        this.$bvModal.show("statements-export");
      }, 20);
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchWithdrawals(this.currency.id);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;
      this.fetchWithdrawals(this.currency.id);
    },
    fetchWithdrawals(currency_id) {
      this.loading = true;
      this.withdrawals = [];

      var data = {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        currency_id
      };
      this.pagination.totalPages = 1;

      serviceWithdraw
        .search(data)
        .then((response) => {
          this.withdrawals = response.data;
          this.pagination.totalPages = response.last_page;
          this.$emit('fetch-reports', this.currency.id)
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false });
    },
    showToast(toastOptions) {
      this.$bvToast.toast(toastOptions.message, {
        title: toastOptions.title,
        variant: toastOptions.variant,
        autoHideDelay: toastOptions.autoHideDelay,
        appendToast: toastOptions.appendToast,
      });
    },
  }
}
</script>

<style scoped lang="scss">
.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}
.btns-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .header-table {
    display: grid;
    grid-template-columns: 50px 1fr;
  }
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: rgba(0, 148, 136, 0.1);
}
.btn-table:first-child:active {
  background: rgba(0, 148, 136, 0.1);
  border: none;
}
.btn-table.heaven:first-child {
  background: $base-color-heaven-light;
}
.btn-table.heaven:first-child:active {
  background: $base-color-heaven-light;
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}
.input-busca {
  width: 350px;
  height: 60px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
  margin-bottom: 0px !important;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}
@media screen and (max-width: 768px) {
  .input-busca {
    width: 100%;
  }
}
.search {
  position: absolute;
  left: 22px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 1;
}
.inputSearch {
  position: relative;
}
.filter {
  font-weight: 600;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .filter {
    display: none;
  }
  .Table-body {
    padding: 15px;
  }

  header {
    display: block;
  }
  .item-valor {
    text-align: left;
    margin: 0 !important;
    max-width: 270px;
  }
  .item-valor + .item-valor {
    margin-top: 15px !important;
  }
  .item-valor p {
    font-size: 18px;
  }
}
.link-venda {
  font-size: 12px;
  color: #2133d2;
  font-weight: 600;
}
.limpa-filtro {
  font-size: 13px;
  max-width: 60%;
}
.limpa-filtro span {
  color: #81858e;
}
.limpa-filtro a {
  color: #2133d2;
}
</style>